<template>
  <g>
    <svg:style>
      .cls-10, .cls-11, .cls-12, .cls-13, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-3 {
        fill: url(#handle-gradient);
      }

      .cls-4 {
        fill: url(#handle-gradient-2);
      }

      .cls-5 {
        fill: url(#handle-gradient-3);
      }

      .cls-6 {
        fill: url(#handle-gradient-4);
      }

      .cls-7 {
        fill: url(#handle-gradient-5);
      }

      .cls-8 {
        fill: url(#handle-gradient-6);
      }

      .cls-9 {
        fill: url(#handle-gradient-7);
      }

      .cls-10 {
        fill: url(#handle-gradient-8);
      }

      .cls-11 {
        fill: url(#handle-gradient-9);
      }

      .cls-12 {
        fill: url(#handle-gradient-10);
      }

      .cls-13 {
        fill: url(#handle-gradient-11);
      }
    </svg:style>

    <linearGradient id="handle-gradient"
                    :x1="doorLeftWidth + 11.32"
                    :y1="doorTopHeight + 150.76"
                    :x2="doorLeftWidth + 16.99"
                    :y2="doorTopHeight + 150.76"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="handle-gradient-2"
                    data-name="handle-gradient"
                    :x1="doorLeftWidth + 90.6"
                    :y1="doorTopHeight1 + 22.23"
                    :x2="doorLeftWidth + 90.6"
                    :y2="doorTopHeight1 + 279.3"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="handle-gradient-3"
                    data-name="handle-gradient"
                    :x1="doorLeftWidth + 51.61"
                    :y1="doorTopHeight1 + 22.23"
                    :x2="doorLeftWidth + 51.61"
                    :y2="doorTopHeight1 + 279.3"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="handle-gradient-4"
                    data-name="handle-gradient"
                    :x1="doorLeftWidth + 70.99"
                    :y1="doorTopHeight1 + 101.16"
                    :x2="doorLeftWidth + 70.99"
                    :y2="doorTopHeight1 + 81.31"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="handle-gradient-5"
                    data-name="handle-gradient"
                    :x1="doorLeftWidth + 70.99"
                    :y1="doorTopHeight1 + 140.85"
                    :x2="doorLeftWidth + 70.99"
                    :y2="doorTopHeight1 + 121"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="handle-gradient-6"
                    data-name="handle-gradient"
                    :x1="doorLeftWidth + 70.99"
                    :y1="doorTopHeight1 + 180.52"
                    :x2="doorLeftWidth + 70.99"
                    :y2="doorTopHeight1 + 160.68"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="handle-gradient-7"
                    data-name="handle-gradient"
                    :x1="doorLeftWidth + 70.99"
                    :y1="doorTopHeight1 + 220.21"
                    :x2="doorLeftWidth + 70.99"
                    :y2="doorTopHeight1 + 200.37"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="handle-gradient-8"
                    data-name="handle-gradient"
                    :x1="doorLeftWidth + 70.99"
                    :y1="doorTopHeight1 + 94.78"
                    :x2="doorLeftWidth + 70.99"
                    :y2="doorTopHeight1 + 87.7"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="handle-gradient-9"
                    data-name="handle-gradient"
                    :x1="doorLeftWidth + 70.99"
                    :y1="doorTopHeight1 + 134.47"
                    :x2="doorLeftWidth + 70.99"
                    :y2="doorTopHeight1 + 127.38"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="handle-gradient-10"
                    data-name="handle-gradient"
                    :x1="doorLeftWidth + 70.99"
                    :y1="doorTopHeight1 + 174.15"
                    :x2="doorLeftWidth + 70.99"
                    :y2="doorTopHeight1 + 167.06"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="handle-gradient-11"
                    data-name="handle-gradient"
                    :x1="doorLeftWidth + 70.99"
                    :y1="doorTopHeight1 + 213.82"
                    :x2="doorLeftWidth + 70.99"
                    :y2="doorTopHeight1 + 206.74"
                    xlink:href="#handle-gradient"/>

    <g id="L13">
      <rect id="inox"
            v-if="showInox"
            data-name="inox"
            class="cls-4"
            :x="inox1X"
            :y="inox1Y"
            width="2.13"
            :height="inoxHeight"/>

      <rect id="inox-2"
            v-if="showInox"
            data-name="inox"
            class="cls-5"
            :x="inox2X"
            :y="inox1Y"
            width="2.12"
            :height="inoxHeight"/>

      <path id="inox-3"
            v-if="showInox"
            data-name="inox"
            class="cls-6"
            :d="`
            M ${doorLeftWidth1 + 61.07} , ${doorTopHeight1 + 81.31}
            v 19.85
            H ${doorLeftWidth1 + 80.91}
            V ${doorTopHeight1 + 81.31}
            H ${doorLeftWidth1 + 61.07}
            Z
            M ${doorLeftWidth1 + 78.78} , ${doorTopHeight1 + 99}
            H ${doorLeftWidth1 + 63.19}
            V ${doorTopHeight1 + 83.45}
            h 15.6
            V ${doorTopHeight1 + 99}
            Z
            `"/>

      <path id="inox-4"
            v-if="showInox"
            data-name="inox"
            class="cls-7"
            :d="`
            M ${doorLeftWidth1 + 61.07} , ${doorTopHeight1 + 121}
            v 19.85
            H ${doorLeftWidth1 + 80.91}
            V ${doorTopHeight1 + 121}
            H ${doorLeftWidth1 + 61.07}
            Z
            m 17.72 , 17.72
            H ${doorLeftWidth1 + 63.19}
            V ${doorTopHeight1 + 123.13}
            h 15.6
            v 15.59
            Z
            `"/>

      <path id="inox-5"
            v-if="showInox"
            data-name="inox"
            class="cls-8"
            :d="`
            M ${doorLeftWidth1 + 61.07} , ${doorTopHeight1 + 160.68}
            v 19.84
            H ${doorLeftWidth1 + 80.91}
            V ${doorTopHeight1 + 160.68}
            H ${doorLeftWidth1 + 61.07}
            Z
            M ${doorLeftWidth1 + 78.78} , ${doorTopHeight1 + 178.4}
            H ${doorLeftWidth1 + 63.19}
            v -15.6
            h 15.6
            v 15.6
            Z
            `"/>

      <path id="inox-6"
            v-if="showInox"
            data-name="inox"
            class="cls-9"
            :d="`
            M ${doorLeftWidth1 + 61.07} , ${doorTopHeight1 + 200.37}
            v 19.84
            H ${doorLeftWidth1 + 80.91}
            V ${doorTopHeight1 + 200.37}
            H ${doorLeftWidth1 + 61.07}
            Z
            m 17.72 , 17.71
            H ${doorLeftWidth1 + 63.19}
            V ${doorTopHeight1 + 202.49}
            h 15.6
            v 15.59
            Z
            `"/>

      <polyline id="inox-7"
                v-if="showInox"
                data-name="inox"
                class="cls-10"
                :points="`
                ${doorLeftWidth1 + 74.53} ${doorTopHeight1 + 87.7}
                ${doorLeftWidth1 + 67.44} ${doorTopHeight1 + 87.7}
                ${doorLeftWidth1 + 67.44} ${doorTopHeight1 + 94.78}
                ${doorLeftWidth1 + 74.53} ${doorTopHeight1 + 94.78}
                ${doorLeftWidth1 + 74.53} ${doorTopHeight1 + 87.7}
                `"/>

      <polyline id="inox-8"
                v-if="showInox"
                data-name="inox"
                class="cls-11"
                :points="`
                ${doorLeftWidth1 + 74.53} ${doorTopHeight1 + 127.38}
                ${doorLeftWidth1 + 67.44} ${doorTopHeight1 + 127.38}
                ${doorLeftWidth1 + 67.44} ${doorTopHeight1 + 134.47}
                ${doorLeftWidth1 + 74.53} ${doorTopHeight1 + 134.47}
                ${doorLeftWidth1 + 74.53} ${doorTopHeight1 + 127.38}
                `"/>

      <polyline id="inox-9"
                v-if="showInox"
                data-name="inox"
                class="cls-12"
                :points="`
                ${doorLeftWidth1 + 74.53} ${doorTopHeight1 + 167.06}
                ${doorLeftWidth1 + 67.44} ${doorTopHeight1 + 167.06}
                ${doorLeftWidth1 + 67.44} ${doorTopHeight1 + 174.15}
                ${doorLeftWidth1 + 74.53} ${doorTopHeight1 + 174.15}
                ${doorLeftWidth1 + 74.53} ${doorTopHeight1 + 167.06}
                `"/>

      <polyline id="inox-10"
                v-if="showInox"
                data-name="inox"
                class="cls-13"
                :points="`
                ${doorLeftWidth1 + 74.53} ${doorTopHeight1 + 206.74}
                ${doorLeftWidth1 + 67.44} ${doorTopHeight1 + 206.74}
                ${doorLeftWidth1 + 67.44} ${doorTopHeight1 + 213.82}
                ${doorLeftWidth1 + 74.53} ${doorTopHeight1 + 213.82}
                ${doorLeftWidth1 + 74.53} ${doorTopHeight1 + 206.74}
                `"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
  ],
  data() {
    return {
      inoxOffset: 22.26,
      leaf2Left: 7,
    }
  },
  computed: {
    inoxHeight() {
      return this.showBg ?
        this.doorHeight - 2 * this.inoxOffset :
        this.doorHeight - 2 * this.leaf2Left
    },
    inox1X() {
      return this.doorLeftWidth + this.doorWidth * .65
    },
    inox2X() {
      return this.doorLeftWidth + this.doorWidth * .35
    },
    inox1Y() {
      return this.showBg ?
        this.doorTopHeight + this.inoxOffset :
        this.doorTopHeight + this.leaf2Left
    },
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 150
    },
  },
}
</script>
